<template>
  <div class="widgetContainer userProfile">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('personal_profile_header') }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    <div class="widgetContainer__body personal-profile-body">
      <div class="listWithInfo border-1">
        <ul>
          <li>
            <p class="label">
              {{ $t('profile_name') }}
            </p>
            <p class="value">
              {{ getPersonDetails | contactName }}
            </p>
          </li>
          <li>
            <p class="label">
              {{ $t('profile_number') }}
            </p>
            <p class="value">
              {{ getPersonDetails.phone | phone }}
            </p>
          </li>
          <li>
            <p class="label">
              {{ $t('profile_email') }}
            </p>
            <p class="value">
              {{ getPersonDetails.email }}
            </p>
          </li>
        </ul>
      </div>

      <p class="header-2">
        {{ $t('profile_business_header') }}
      </p>
      <div class="listWithInfov2" v-if="businessList.length !== 0">
        <ul>
          <li class="business_tile" v-for="b in businessList" :key="b.id" @click="showBusiness(b)">
            <p class="label">
              {{ b.legalName }}
            </p>
            <p class="value">
              {{ `${b.kyb.status === 'approved' ? '' : String.prototype.toUpperCase.call(b.kyb.status)}` }}
            </p>
          </li>
        </ul>
      </div>

      <div>
        <p class="header-2">
          {{ $t('profile_action_header') }}
        </p>
        <div class="listWithActionsv2">
          <ul>
            <li v-if="!$store.getters['user/isPiloting']" @click="addBusiness()">
              <div class="details">
                <p class="label">
                  Add Business
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right icon" />
              </div>
            </li>
            <li @click="drawerPush('Managers')">
              <div class="details">
                <p class="label">
                  Managers
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right icon" />
              </div>
            </li>
            <li @click="helpCenter()">
              <div class="details">
                <p class="label">
                  {{ $t('profile_helpcenter') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right icon" />
              </div>
            </li>
            <li @click="openHelp()">
              <div class="details">
                <p class="label">
                  {{ $t('profile_getintouch') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right icon" />
              </div>
            </li>
            <li @click="openDisclosures()">
              <div class="details">
                <p class="label">
                  {{ $t('profile_disclosures') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right icon" />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="addressWithInfo">
        <div class="addressWithInfo__header flex-between-center">
          <p class="header-2">
            {{ $t('location') }}
          </p>
          <span v-if="!$store.getters['user/isPiloting']" @click="editAddress()">
            {{ $t('Edit') }}
          </span>
        </div>

        <div class="addressWithInfo__body border-1">
          <div class="map">
            <ul class="card-with-map">
              <div class="map">
                <GmapMap
                  v-if="lat && lng"
                  :center="{ lat, lng }"
                  :zoom="7"
                  map-type-id="terrain"
                  style="width: 100%; height: 200px">
                  <GmapMarker
                    :position="{ lat, lng }"
                    :clickable="true"
                    :draggable="false" />
                </GmapMap>
              </div>

              <ul>
                <li>
                  <span class="label">{{ $t('address') }}</span>
                  <span class="value">{{ address }}</span>
                </li>
                <li>
                  <span class="label">{{ $t('contact_country_title') }}</span>
                  <span class="value">
                    {{ getPersonDetails.address.country }}
                  </span>
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="el-drawer__footer">
      <el-button type="primary" @click="logoutHandler" class="logout brad width-100 el-button__brand brand">
        {{ $t('profile_logoutn_btn') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';
import { mapGetters } from 'vuex';
import loginMixins from '@m/user/mixins/login';
import { getBrandConfig } from '@/utils/env';
export default {
  mixins: [loginMixins],
  data() {
    return {
      lat: null,
      lng: null,
    };
  },
  created() {
    this.$store.dispatch('kyb/listAllBusiness');
    this.getLatLong();
  },
  computed: {
    ...mapGetters('user', ['getPersonDetails']),
    ...mapGetters({
      businessList: 'kyb/getBusinessList'
    }),
    google: gmapApi,
    address() {
      const user = this.getPersonDetails;
      const address = user.address;
      if (address.line2 && address.line2.length > 0) {
        return (
          address.line1 +
          ', ' +
          address.line2 +
          ', ' +
          address.city +
          ', ' +
          address.state
        );
      }
      return address.line1 + ', ' + address.city + ', ' + address.state;
    }
  },
  methods: {
    showBusiness(business) {
      const status = business.kyb.status
      if (status != 'approved'){
        if (status == 'declined') this.$root.$message({
          type: 'error',
          message: `This business is ${status}`,
          duration: 5000
        });
        else this.$root.$message({
          type: 'info',
          message: `${String.prototype.toUpperCase.call(status)}, Please wait while we review.`,
          duration: 5000
        });
        return false;
      }
      this.$store.commit('dashboard/setSelectedBusiness',business);
      this.drawerPush('BusinessDetails');
    },
    addBusiness() {
      this.drawerPush('AddBusiness');
    },
    done() {
      this.goToDashboard();
    },
    editAddress() {
      this.drawerPush('EditUserAddress');
    },
    popupClose() {
      this.editAddressDialog = false;
    },
    getLatLong() {
      this.$gmapApiPromiseLazy().then(() => {
        if (this.google) {
          /** @type {import('@m/user/store/state').userState['personDetails'] } */
          const user = this.getPersonDetails;
          const geocoder = new this.google.maps.Geocoder();
          const address = user.address;
          const addressString =
            address.line1 +
            ',' +
            address.line2 +
            ',' +
            address.city +
            ',' +
            address.country;

          geocoder.geocode(
            {
              address: addressString
            },
            (results, status) => {
              if (status == this.google.maps.GeocoderStatus.OK) {
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();
                this.lat = latitude;
                this.lng = longitude;
              }
            }
          );
        }
      });
    },
    logoutHandler() {
      this.logoutUser();
    },
    helpCenter() {
      var win = window.open(
        getBrandConfig('helpCenter','https://intercom.help/solid-2cf7e39d14f4/en/'),
        '_blank'
      );
      win.focus();
    },
    openDisclosures() {
      var win = window.open(
        getBrandConfig('disclosures','https://intercom.help/solid-2cf7e39d14f4/en/collections/2921684-legal-disclosures-terms-policies'),
        '_blank'
      );
      win.focus();
    },
  }
};
</script>

<style lang="scss">
.userProfile {
  max-height: none;

  .loginHeader {
    .submit {
      margin-left: auto;
    }
  }

  .logout {
    font-size: 17px;
    background-color: #0e0e0f;
    color: #fff;
    padding: 14px;
    line-height: 21px;
  }
}
.el-drawer {
  .el-drawer__body {
    .widgetContainer__body {
      &.personal-profile-body {
        padding-bottom: 60px;
      }
      li.business_tile{
        padding-left: .5rem;
        padding-right: .5rem;
        cursor: pointer;
        &:hover {
          background: var(--branding-primary);
          p{
            color: white !important;
          }
        }
      }
    }
  }
}
</style>
